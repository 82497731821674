.about-cols-wrap {
  display:flex;
  padding:45px 0 65px;
  background: url(../imgs/line.png) bottom repeat-x;
  @media(max-width:900px) {
    padding:25px 0 35px;
  }
  @media(max-width:600px) {
    padding:0 0 15px;
  }
  @media(max-width:450px) {
    padding:0;
  }
  .col {
    flex-grow: 1;
    .title-wrap {
      margin:15px 0 30px;
    }
    p {
      margin-top:1.5em;
      &:nth-child(1) {
        margin-top:0;
      }
    }
    &:nth-child(1) {
      width:calc(50% - 80px);
    }
    &:nth-child(2) {
      width:calc(50%);
      margin-left:80px;
    }
  }
  &.full {
    .col {
      .txt-wrap {
        column-count: 2;
        column-gap: 100px;
        @media(max-width:960px) {
          column-gap: 65px;
        }
        @media(max-width:600px) {
          column-count: 1;
        }
      }
    }
  }
  @media(max-width:900px) {
    flex-direction: column;
    .col {
      &:nth-child(1) {
        width:calc(100%);
        margin-bottom:50px;
        @media(max-width:600px) { margin-bottom:35px; }
        @media(max-width:450px) { margin-bottom:20px; }
      }
      &:nth-child(2) {
        width:calc(100%);
        margin-left:0;
        margin-bottom:50px;
        @media(max-width:600px) { margin-bottom:35px; }
        @media(max-width:450px) { margin-bottom:20px; }
      }
    }
    &:nth-child(even) {
      .col {
        &:nth-child(1) {
          order:0;
          margin-left:0;
        }
        &:nth-child(2) {
          margin-left:0;
        }
      }
    }
  }
  &.top {
    padding-top:0;
  }
}

.supporting-organisations {
  padding:50px 0;
  ul {
    display: flex;
    margin-left:-17.5px;
    margin-right:-17.5px;
    flex-wrap: wrap;
    li {
      width:calc(33.333% - 35px);
      margin-left:17.5px;
      margin-right:17.5px;
      margin-top:40px;
      text-align: center;

      @media(max-width:700px) {  width:calc(33% - 35px);  }
      @media(max-width:550px) {  width:calc(50% - 35px);  }
      @media(max-width:350px) {  width:calc(100% - 35px);  }
      .img-wrap {
        height:150px;
        background:#fff;
        border: 1px solid #D8D8D8;
        overflow: hidden;
        display:flex;
        justify-content:center;
        align-items:center;
        padding: 20px;
        img {
          max-height:130px;
        }
      }
      h4 {
        padding:5px 10px;
        line-height:1.125;
        a {
          color:#388EA0;
        }
      }
    }
  }
}
