.contact-header {
  display:flex;
  flex-wrap:wrap;
  margin:0;
  .col {
    width:50%;
    font-size:16px;
    font-style: normal;
    font-weight: 400;
    @media(max-width:900px) {
      width:100%;
      &:nth-child(2) {
        margin-top:50px;
      }
    }
    .contact-details {
      margin:0;
      padding:25px 0;
      .row {
        margin:7.5px 0;
        color:#435157;
        display:flex;
        align-items:center;
        align-content:center;
        span {
          width:43px;
          height:43px;
          line-height:47px;
          background:url('../imgs/contact_details_ico.png') no-repeat;
          display: inline-block;
          text-align:center;
          margin-right:12px;
          i {
            color:#fff;
            font-size:22px;
          }
        }
        a {
          color:#435157;
          text-decoration: underline;

        }
      }
    }
    .social-media-wrap {
      margin-top:20px;
      h2 {
        font-size: 18px;
        color:$pink;
        letter-spacing: 0;
        line-height: 1.3;
      }
      p {
        color: #4A4A4A;
        letter-spacing: 0;
        line-height: 1.35;
        margin:0 0 10px;
      }
      .social-media-item {
        width:50px;
        height:50px;
        color:#fff;
        display: inline-block;
        margin-right:7px;
        text-align:center;
        line-height: 60px;
        i {
          line-height:1;
          color:#fff;
          font-size:29px;
        }
        &[href*="facebook"] {
          background-image:url('../imgs/facebook_bck.png');
        }
        &[href*="twitter"] {
          background-image:url('../imgs/twitter_bck.png');
        }
      }
    }
  }
}

.contact-map {
  margin-top:50px;
}