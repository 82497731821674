.homepage {
  #page-wrap-inner {
    padding-top:0;
    header.page-header {
      &:after {
        opacity: 0.83;
      }
    }

    .content-area-wrap {
      .inner-wrap {
        max-width:1132px;
      }
    }

    .featured-items {
      margin:70px auto;
      .lists {
        display:flex;
        margin-left:-15px;
        margin-right:-15px;
        li {
          margin-left:15px;
          margin-right:15px;
          width:calc(33.333% - 30px);
          color:rgba(#FFFFFF, 0.49);
          font-family:$second-title-font-family;
          font-size:18px;
          line-height:1.1;
          font-style: normal;
          font-weight: 400;
          @media(max-width:900px) { font-size:17px; }
          @media(max-width:600px) { font-size:16px; }
          @media(max-width:450px) { font-size:15px; }
          a {
            color:rgba(#fff, 0.49);
            text-decoration: none;
            display: block;
            @media(max-width:960px) {
              display: flex;
            }
            &:hover {
              text-decoration: none;
            }
            .img-wrap {
              height:194px;
              overflow: hidden;
              background-size:cover;
              background-repeat:no-repeat;
              @media(max-width:420px) {
                height: auto;
              }
              img {
                opacity:0;
                @media(max-width:420px) {
                  opacity: 1;
                  width: 100%;
                }
              }
            }
            .txt-wrap {
              padding:20px;
              h3 {
                font-size: 20px;
                color: #FFFFFF;
                letter-spacing: 0;
                line-height: 1.15;
                font-family:$second-title-font-family;
                font-style: normal;
                font-weight: 400;
                margin:0 0 5px;
                @media(max-width:900px) { font-size:18px; }
                @media(max-width:600px) { font-size:16px; }
                @media(max-width:450px) { font-size:15px; }
              }
              p {
                margin:0;
              }
            }
          }
          &:nth-child(4n+1) {
            background:#D1345B;

          }
          &:nth-child(4n+2) {
            background:#EFD03F;
          }
          &:nth-child(4n+3) {
            background:#3891A6;
          }
          &:nth-child(4n+4) {
            background:#4C5B5C;
          }
        }
        @media(max-width:960px) {
          flex-wrap:wrap;
          li {
            display: flex;
            width:calc(50% - 30px);
            margin-bottom:30px;
            .img-wrap {
              width:40%;
              height:auto;
            }
            .txt-wrap {
              width:calc(58%);
              display:inline-block;
              display:flex;
              align-items:center;
            }
          }
        }
        @media(max-width:760px) {
          flex-direction: column;
          margin:0;
          li {
            width:calc(100%);
            margin-left:0;
            margin-right:0;
          }

        }
        @media(max-width:420px) {
          li {
            display:block;
            width:100%;
            a {
              flex-direction: column;
            }
            .img-wrap {
              width:100%;
              background-image:none !important;
              img {
                opacity:1;
                width:100%;
              }
            }
            .txt-wrap {
              width:100%;
              display:block;
            }
          }
        }
      }
    }
  }
}

.txt-cols-wrap {
  display:flex;
  width:90%;
  margin:105px auto;
  flex-wrap: wrap;
  .col {
    width:50%;
    @media(max-width:900px) { width:100%; }
    .txt-wrap {
      padding-left:80px;
      @media(max-width:900px) {
        padding-left:0;
        padding-top:60px;
      }
      p {
        margin:0.75em 0 0;
        strong {
          font-weight:600;
        }
      }
      .btn {
        margin-top:25px;
        background:#F8A944;
      }
    }
  }
  &.alt {
    .col {
      &:nth-child(1) {
        order:2;
        @media(max-width:900px) {
          order:0;
        }
      }
      .txt-wrap {
        padding-left:0;
        padding-right:80px;
        @media(max-width:900px) {

          padding-right:0;
        }
      }
    }

  }
}


.featured-photos {
  .title-wrap {
    margin-top:42.5px;
    background-size:auto;
    p.summary {
      font-size: 16px;
      color: #4A4A4A;
      letter-spacing: 0;
      line-height: 1.33;
      max-width:815px;
      margin:40px auto;
      @media(font-size:600px) { font-size:15px; }
      @media(font-size:450px) { font-size:14px; }
    }
  }
  .featured-photos-items {
    //display: flex;
    margin:50px 0;
    position: relative;
    a {
      margin:0 15px;
      display: inline-block;
      img {
        height: 245px;
      }
    }
    .slick-prev,
    .slick-next {
      position: absolute;
      top:calc(50% - 23.5px);
      width:auto;
      cursor: pointer;
      z-index: 2000;
    }
    .slick-prev {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      left:0;
      background: url(../imgs/left-arrow.png);
      background-repeat: no-repeat;
      background-color: #F8A944;
      background-position: 13px 11px;
      text-indent: -9999px;

    }
    .slick-next {
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      right:0;
      background: url(../imgs/right-arrow.png);
      background-repeat: no-repeat;
      background-color: #F8A944;
      background-position: 21px 11px;
      text-indent: -9999px;
    }
  }
}
