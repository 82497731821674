
.fancybox-slide > * {
  padding:0;
}
.dialog-details-wrap {
  display:none;
  width:90%;
  max-width: 885px;
  text-align: center;
  .fancybox-close-small {
    background: #9F1C44;
    height:45px;
    width:45px;
    &:after {
      color:white;
      height:35px;
      width:35px;
      font-size:20px;
      top:6.5px;
      right:4px;
      font-family: FontAwesome;
      content: "\f00d";
    }
    @media(max-width:450px) {
      width:35px;
      height:35px;
      &:after {
        width:25px;
        height:25px;
        top:1.5px;
      }
    }
    &:hover {
      background:rgba(#9F1C44, 0.9);
      &:after {
        color:white;
        background:none;
      }
    }
  }
  .wrap {
    display:flex;
    background:#fff;
    @media(max-width:900px) {
      flex-direction: column;
    }
    .col {
      width:50%;
      @media(max-width:900px) {
        width:100%;
      }
      &.cover {
        background-size:cover;
        background-position: top center;
        background-repeat: no-repeat;
        min-height:300px;
        img {
          opacity:0;
        }
      }
      &:nth-child(1) {
        display:flex;
        justify-content:center;
        align-items:center;
        padding:70px;
      }
      &:nth-child(2) {
        background: #F2F2F2;
        padding:70px;
        text-align:left;
        position: relative;
        @media(max-width:900px) {
          position: static;
        }
        @media(max-width:600px) {
          padding:60px;
        }
        @media(max-width:450px) {
          padding:50px;
        }
        @media(max-width:400px) {
          padding:40px;
        }
      }
      .controls-wrap {
        position: absolute;
        top:0;
        right:45px;
        width:90px;
        height:45px;
        display:flex;
        span {
          width:50%;
          height:45px;
          background:#BD2050;
          display:inline-block;
          text-align:center;
          line-height: 42px;
          cursor: pointer;
          color:#fff;
          font-size:20px;
          position: relative;
          &:nth-child(1) {
            border-right:1px rgba(#EDF4FF, 0.11) solid;
          }
          &:hover {
            background:rgba(#BD2050, 0.9);
          }
        }
        @media(max-width:450px) {
          width:70px;
          right:35px;
          span {
            height:35px;
            line-height:33px;
          }
        }
      }
      h3 {
        font-size: 33px;
        color: #BD2050;
        letter-spacing: 0;
        line-height: 1.3;
        font-weight:500;
        font-style: normal;
        padding:0;
        margin:0;
        margin-bottom:35px;
        @media(max-width:600px) {
          font-size:30px;
        }
        @media(max-width:450px) {
          font-size:27px;
        }
      }
      .desc {
          font-size: 15px;
          color: #656565;
          letter-spacing: 0;
          line-height: 1.75;
          @media(max-width:600px) {
            font-size:14px;
          }
          @media(max-width:450px) {
            font-size:13px;
          }
          p {
            margin:1.5em 0 0;
            &:nth-child(1) {
              font-weight:700;
            }
          }
      }
      .btn {
          background: #2D2D2D;
          border-radius: 4px;
          width:100%;
          max-width:158px;
          color:#fff;
          text-align:center;
          margin-top:35px;
          &[href=""] {
            display:none;
          }
      }
    }
  }
}


// TERMS AND CONDITONS

.dialog-terms {
  max-width:600px;
  width:90%;
  .dialog-terms-header {
    padding:35px;
    color:#fff;
    @media(max-width:900px) { padding:30px; }
    @media(max-width:600px) { padding:25px; }
  }
  .dialog-terms-inner {
    padding:0 35px 35px;
    @media(max-width:900px) { padding:0 30px 30px; }
    @media(max-width:600px) { padding:0 25px 25px; }
    a {
      text-decoration: underline;
      color:#616161;
      &.btn {
        color:#fff;
        text-decoration: none;
      }
    }
    .btn-wrap {
      display: block;
      margin-top:35px;
      padding-top:35px;
      border-top:1px solid #F4F4F4;
      a.btn {
        margin-right: 7.5px;
        text-transform: uppercase;
      }
    }
  }
  a {
    &:focus {
      outline: none;
    }
  }
  h1 {
    font-size:30px;
    color: #202020;
    margin:0.5em 0;
    line-height: 1;
    text-transform: uppercase;
    @media(max-width:900px) {
      font-size: 26px;
    }
    @media(max-width:600px) {
      font-size: 22px;
    }
  }
  p.caption-txt {
    color: #A1A1A1;
    //color:#fff;
    //text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    letter-spacing: 0;
    font-weight:400;
    margin:8px 0 0;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3;
  }
  ul {
    margin: 0 0 1.5em;
    &#validation {
      margin-top:1.5em;
    }
    ul {
      list-style: disc;
      padding:0 10px;
      li {
        padding:0;
        margin-left:15px;
        margin-right:10px;
      }
    }
  }
  p {
    margin:0 0 1.5em;
    font-size: 16px;
    color: #6D6D6D;
    letter-spacing: 0;
    line-height: 1.45em;
    &:first-child {
      font-style: normal;
      font-weight: 700;
    }
  }
  &.dialog-close {
    .dialog-terms-inner {
      padding-top:35px;
      @media(max-width:900px) { padding-top:30px; }
      @media(max-width:600px) { padding-top:25px; }
    }
  }
}
