.slideshow {
  position: relative;
  z-index: 100;
  margin-bottom:70px;
  &:after {
    content: " ";
    background:url('../imgs/slideshow_btm.png') bottom center repeat-x;
    height:138px;
    width:100%; 
    position:absolute;
    bottom:-70px;
    left:0;
    z-index: 200;
  }
  .slide {
    position: relative;
    height:760px;
    width:100%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: right;
    max-height:100vh;
    @media(max-width:900px) { height:700px; }
    @media(max-width:600px) { height:625px; }
    @media(max-width:450px) { height:535px; }
    .img-overlay {
      opacity: 0.7;
      background-image: linear-gradient(-90deg, #000000 0%, rgba(0,0,0,0.29) 100%);
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    .txt-wrap {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      .inner-wrap {
        display:flex;
        align-items:center;
        height:100%;
        div {

        }
        h1 {
          font-family:$title-font-family;
          letter-spacing: 0;
          margin:0;
          font-size: 92px;
          line-height:1;
          color: #FFFFFF;
          letter-spacing: 0;
          text-shadow: 0 0 20px rgba(0,0,0,0.56);
          @media(max-width:900px) { font-size:79px; }
          @media(max-width:600px) { font-size:60px; }
          @media(max-width:450px) { font-size:45px; }
        }
        p {
          font-family:$third-title-font-family;
          font-size: 24px;
          font-weight:600;
          font-style: normal;
          letter-spacing: 0;
          line-height:1.3;
          text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
          margin:10px 0;
          color:#fff;
          @media(max-width:900px) { font-size:24px; }
          @media(max-width:600px) { font-size:20px; }
          @media(max-width:450px) { margin:5px 0; }

        }
        .btn-wrap {
          margin-top:25px;
          max-width:290px;
          width:100%;
          a {
            font-size: 14px;
            padding:10px 20px;
            font-family:$third-title-font-family;
            font-style: normal;
            font-weight: 700;
            width:calc(50% - 5px);
            text-align:center;
            @media(max-width:900px) { font-size:18px; }
            @media(max-width:600px) { font-size:16px; }
            @media(max-width:450px) { font-size:15px; }
            &:nth-child(1) {
              border:1px $pink solid;
              background:$pink;
              margin-right:10px;
            }
            &:nth-child(2) {
              border:1px #FFFFFF solid;
              background:none;
            } 
          }
        }
      }
    }
  }
}