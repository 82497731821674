button {
  border:0;
}
.btn {
  background:#F8A944;
  border:0;
  color: #fff;
  cursor: pointer;
  display:inline-block;
  position:relative;
  text-transform:uppercase;
  font-family:$title-font-family;
  font-weight:900;
  font-style: normal;
  font-size:16px;
  padding:10px 15px;
  text-decoration: none;
  @media(max-width:900px) { font-size:15px;  }
  @media(max-width:600px) { font-size:14px; padding:12px 27px; }
  @media(max-width:450px) { font-size:13px; padding:10px 25px; }
  &:active {
    top:1px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: #FFFFFF;
  }
}

.back-btn {
  display:inline-block;

}

/* Social Media */
.social-items {
  margin:35px auto;
  a {
    width:50%;
    display:inline-block;
    text-align:center;
    padding:5px;
    color:#fff;
    font-family:$title-font-family;
    font-size: 14px;
    font-weight:900;
    text-transform: uppercase;
    &:hover {  text-decoration:none; }
    i {
      display:inline-block;
      margin:2.5px 17.5px 0 0;
      font-size:20px;
    }
  }
  a[href^="https://www.facebook.com"] {
    background:#425E99;
    &:hover { background:rgba(#425E99, 0.7); }
  }
  a[href^="https://twitter.com"] {
    background:#1C94E0;
    &:hover { background:rgba(#1C94E0, 0.7); }
  }
}


i.more-info-ico {
  display: inline-block;
  margin-left:5px;
  width:20px;
  height:20px;
  border-radius:100%;
  border:1px #fff solid;
  text-align:center;
  line-height: 18px;
  padding-left:1px;
}

.btn-txt {
  font-size: 16px;
  color: #698C9D;
  line-height: 22px;
  font-weight:600;
  display:inline-block;
  margin: 0 20px;
  border-bottom:2px solid #698C9D;
  &:hover {
    text-decoration: none;
  }
}
