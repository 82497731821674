.column {
    float: left;
}
.size-1of4 {
    width: 25%;
}
.size-1of3 {
    width: 33.333%;
}
.size-1of2 {
    width: 50%;
}

@media screen and (max-width: 600px) {
    .resources-items[data-columns]::before {
        content: '1 .column';
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .resources-items[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
    .resources-items[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}

@media screen and (min-width: 1001px) {
    .resources-items[data-columns]::before {
        content: '4 .column.size-1of4';
    }
}
