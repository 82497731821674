.general-txt {
  table {
    border-left:1px #D2D2D2 solid;
    border-top:1px #D2D2D2 solid;
    font-size:13px;
    color:#555555;
    font-style: normal;
    font-weight: 700;
    width:100%;
    margin-top:15px;
    margin-bottom:15px;
    thead {
      tr {
        background-color:#E3E8F0;
        color:#000000;
        td, th {
          &:nth-child(1) {
            font-weight:700;
            p {
              font-weight:700;
              &:first-child {
                font-weight:700;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        background:#f7f7f7;
        td {
          border-bottom:1px #D2D2D2 solid;
          border-right:1px #D2D2D2 solid;
          padding:10px 15px;
          font-weight: 400;
          p {
            margin:0;
            font-size:13px;
            color:#555555;
            font-style: normal;
            font-weight: 400;
            &:first-child {
              font-weight:400;
              font-size:13px;
              margin:0;
              color:#555555;
            }
          }
          &:nth-child(1) {
            text-align:center;
            font-weight:600;

          }
        }
        &:nth-child(even) {
          background-color:#FFFFFF;
        }
      }
    }
  }
}
