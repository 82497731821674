.tabs {
  .desktop-only {
    display: none;
    @media(min-width:960px) {
      display: block;
    }
    .tab-btn-select {
      border-bottom:1px #DADADA solid;
      span {
        display:inline-block;
        padding:10px 20px;
        color:#A9A9A9;
        border-right:1px #DADADA solid;
        border-top:1px #DADADA solid;
        position: relative;
        top:1px;
        cursor: pointer;

        font-family: "komu-a",sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0;
        font-size: 16px;


        &.selected {
          color: #01093B;
          background-color:#FBFBFB;
          border-bottom:1px #F6F9FA solid;
        }
        &:hover {
          color:#4A4A4A;
        }
        &:nth-child(1) {
          border-left:1px #DADADA solid;
        }
      }
    }
    .tab-content {
      background:#FBFBFB;
      padding:50px 0;
      .tab-content-inner {
        .row {
          display: flex;
          margin:0;
          .col {
            &:nth-child(1) { 
              width:53.781512605%;
            }
            &:nth-child(2) { 
              width:46%;
            }
          }
          h2 {
            font-family: "komu-a",sans-serif;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            font-size: 60px;
            letter-spacing: 0;
            line-height:1.05;
            max-width:420px;
          }
          a.btn {
            margin:5px 0;
          }
          p {
            margin:1.5em 0 0;
            &:nth-child(1) {
              margin-top:0;
              font-weight:700;
            }
          }
          .doc-wrap {
            padding-top:0;
            a {
              margin-top:0;
            }
          }
        }
      }
    }
  }

  .mobile-only {
    display:none;
    @media(max-width:960px) {
      display: block;
      width:90%;
      margin:0 auto;
    }
    ul {
      list-style: none;
      padding: 0;
      margin:0 0 75px;
      li {
       margin:10px 0;
       border: 1px solid #E3E3E3;
       a.toggle {
          width: 100%;
          display: block;
          background:#FBFBFB;
          height: 80px;
          line-height: 80px;
          transition: background .3s ease;
          padding:0 20px;
          font-size:16px;
          font-weight:600;
          color:#A9A9A9;
          &.active {
            color:#4A4A4A;
          }
        }
        .inner {
          overflow: hidden;
          display: none;
          padding:20px;
          overflow-x: auto;
          ul {
            border:0;
            li {
              margin:0;
              border:0;
            }
          }

          p {
            margin:1.5em 0 0;
            &:nth-child(1) {
              margin-top:0;
              font-weight:700;
            }
          }
        }
      }
    }
  }
}
