footer {
  background:rgba(#2C3430, 0.9);
  position: relative;
  margin-top:50px;
  &:after {
    content: " ";
    height:69px;
    width:100%;
    position: absolute;
    top:-1px;
    left:0;
    background-image:url('../imgs/footer_border_top.png');
    background-repeat: repeat-x;
    background-position: top center;
  }
  .top {
    padding:90px 0 50px;
    text-align:center;
    .title-wrap {
      .subTitle { }
      .title { color:#fff; }
    }
    .figures-wrap {
      display: flex;
      flex-wrap:wrap;
      margin:45px auto 37.5px;
      .col {
        background:#D1345B;
        width:calc(25% - 15px);
        margin:7.5px;
        text-align:center;
        color:#fff;
        padding:50px 0;
        font-family:$second-title-font-family;
        font-style: normal;
        font-weight: 400;
        &:nth-child(4n+1) { background:#D1345B; }
        &:nth-child(4n+2) { background:#3891A6; }
        &:nth-child(4n+3) { background:#4C5B5C; }
        &:nth-child(4n+4) { background:#EFD03F; }
        .figures-value {
          font-size:60px;
          line-height:1;
        }
        .figures-title {
          font-size:20px;
          text-transform: uppercase;
        }
        @media(max-width:900px) {
          width:calc(50% - 15px);
        }
        @media(max-width:490px) {
          width:calc(100% - 15px);
        }

      }
    }
    .btn {
      font-size:23px;
    }
  }
  .btm {
    background:#242B27;
    padding:40px 0;
    color:#898989;
    font-size:13px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6;
    .inner-wrap {
      display: flex;

      @media(max-width:800px) {
        flex-direction: column;
       }
      .col {
        &:nth-child(1) {
          flex-grow:1;
          @media(max-width:800px) {
            width: 100%;
            float: left;
           }
          a {
            color:#898989;
            text-decoration: underline;
          }
        }
        &:nth-child(2) {
          width: 40%;
          float: right;
          @media(max-width:800px) {
            width: 100%;
            float: left;

            margin-top: 50px;
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width:calc(33.333% - 20px);
              margin-left:10px;
              margin-right:10px;
              text-align: center;
              @media(max-width:800px) {
                margin-left:0px;
                margin-right:20px;
              }
              .img-wrap {
                height:80px;
                @media(max-width:800px) {
                  height: 100px;
                  padding:20px;
                }
                background:#fff;
                overflow: hidden;
                display:flex;
                justify-content:center;
                align-items:center;
                padding: 10px;
              }
            }
          }
        }
      }
      @media(max-width:650px) {
        flex-direction: column;
        text-align:center;
        .col {
          &:nth-child(1) {
            margin-bottom:15px;
          }
        }
      }
    }
  }
}
