
ul.docs-wrap {
  list-style: none;
  display:block;
  margin:50px 0;
  padding:25px 0;
  li {
    display:block;
    padding:0;
    margin:35px 0 0;
    &:nth-child(1) {
      margin-top:0;
    }
    a {
      display: block;
      color:#055C8B;
      padding-left:110px;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
      &:before {
        font-family: FontAwesome;
        content: "\f0f6";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        width:90px;
        height:90px;
        line-height:90px;
        font-size:24px;
        background:#E1EBF1;
        position: absolute;
        left:0;
        top:0;
        text-align:center;
        font-size:41px;
      }
      &[href$=".doc"],
      &[href$=".docx"] {
        &:before {
          content: "\f1c2";
        }
      }
      &[href$=".pdf"] {
        &:before {
          content: "\f1c1";
        }
      }
      &[href$=".xsl"],
      &[href$=".xlsx"] {
        &:before {
          content: "\f1c3";
        }
      }
      .file-title {
        font-family:$standard-font-family;
        color:#055C8B;
        font-size:16px;
        font-weight:600;
        display: inline-block;
        line-height: 1em;
        margin-top:5px;
      }
      .file-name {
        font-family:$standard-font-family;
        font-weight:400;
        color:#555555;
        font-size:12px;
        display: block;
        line-height: 1em;
        margin:5px 0 12px;
      }
      .btn {
        font-size:13px;
        padding:10.5px 22px;
        &:after {
          font-size:22px;
          height:34px;
          width:34px;
          padding:8px 0;
          @media(max-width:900px) { line-height:16px; }
          @media(max-width:600px) { line-height:15px; }
        }
      }
    }
  }
}